const style = {
  root: {
    // width: '100%',
    flexGrow: 1,
  },

  button: {
    width: "96px",
    height: "40px",
    // marginRight: '20px',
    marginRight: "11px",
    marginTop: "10px",
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
  },
  helpText: {
    color: "#304d63",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "right",
    float: "right",
  },
  finishButton: {
    /* width: '15%',*/
    marginTop: "10px",
    height: "40px",
    // marginRight: '20px',
    marginRight: "11px",
    fontSize: "13px",
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
  },
  viewProgSum: {
    /* width: '15%',*/
    marginTop: "10px",
    height: "40px",
    fontSize: "13px",
    marginRight: "11px",
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
    //  marginLeft:'20px'
  },
  questionTitleText: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.87)",
    lineHeight: "16px",
    marginBottom: "9px",
    fontWeight: "bold",
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
  },
  rowText: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.87)",
    lineHeight: "16px",
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
  },
  progress: {
    width: "100%",
    marginTop: "24px",
    height: "10px",
    backgroundColor: "#f2f2f2",
    color: "#533278 !important",
    marginBottom: "34px",
  },
  pbottomStyle: {
    marginTop: "34px",
    paddingBottom: "10px",
  },
  disabledButton: {
    backgroundColor: "#f0f0f1",
    color: "#b4b4bb",
    width: "96px",
    height: "40px",
    // marginRight: '20px',
    marginRight: "11px",
    marginTop: "10px",
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
  },
  textField: {
    width: "100%",
    //height: '56px',
    //backgroundColor: '#f1f1f1',
    color: "#19191d",
    borderRadius: "4px",
    marginBottom: "10px",
  },
  hyperText: {
    color: "#533278",
    cursor: "pointer",
    fontWeight: "bold",
    borderBottom: "1px solid #533278",
    // paddingLeft:'5px'
  },
  hyperTextScreen3: {
    color: "#533278",
    cursor: "pointer",
    fontWeight: "bold",
    borderBottom: "1px solid #533278",
    paddingLeft: "5px",
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
  },

  pt_none: {
    pointerEvents: "none",
  },

  pt_auto: {
    pointerEvents: "auto",
  },
};
export default style;
