const style = {
  block: {
    borderRadius: "25px",
    border: "2px solid black",
    textAlign: "center",
    marginRight: "15px",
    verticalAlign: "middle",
    cursor: "pointer",
    height: "120px",
    width: "100%",
  },
  optOut: {
    backgroundColor: "#8c827a",
    width: "100%",
    position: "fixed",
    bottom: "0",
    color: "white",
    zIndex: "2",
    padding: "10px",
  },
  caption: {
    width: "90px",
    height: "30px",
    fontSize: "14px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.75px",
    borderRdius: "4px",
    marginLeft: "25px",
  },
  mainArea: {
    height: "47.2%",
    backgroundColor: "#cccccc",
    padding: "2%",
  },
  welcomeMessage: {
    width: "302px",
    height: "50px",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "40px",
    color: "#304d63",
  },
  modalSubmitBtn: {
    width: "92px",
    height: "36px",
    fontSize: "14px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.75px",
    borderRdius: "4px",
  },
  modalCancelBtn: {
    width: "92px",
    height: "36px",
    fontSize: "14px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    borderRdius: "4px",
    marginRight: "10px",
  },
  startBtn: {
    width: "100px",
    height: "40px",
  },
  copyRightText: {
    color: "#304d63",
    marginTop: "10px",
    marginBottom: "82px",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
  },
};

export default style;
