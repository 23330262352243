import React, { Component } from "react";
import { Auth } from "aws-amplify";
import "./style.css";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { getQueryParams } from "./utils";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

export class SignIn extends Component {
  constructor(props) {
    super(props);
    this.emailInput = React.createRef();
    this.passwordInput = React.createRef();
    this.state = {
      agreeModal: this.props.agreeModal,
    };
  }
  componentDidMount() {
    this.loadQueryParams();
    if (this.props.username && this.props.password) {
      let usernameObj = {
        target: {
          name: "username",
          value: this.props.username,
        },
      };
      let passwordObj = {
        target: {
          name: "password",
          value: this.props.password,
        },
      };
      this.emailInput.current.value = this.props.username;
      this.passwordInput.current.value = this.props.password;
      this.props.handleChange(usernameObj);
      this.props.handleChange(passwordObj);
    }
  }
  handleSubmit = (event) => {
    this.props.handleSubmit(event);
  };

  loadQueryParams = () => {
    let queryParams = getQueryParams();
    if (
      (queryParams.username || queryParams.u) &&
      (queryParams.password || queryParams.p)
    ) {
      let userName = decodeURI(queryParams.username || queryParams.u);
      let password = decodeURI(queryParams.password || queryParams.p);
      this.emailInput.current.value = userName;
      this.passwordInput.current.value = password;

      let usernameObj = {
        target: {
          name: "username",
          value: userName,
        },
      };
      this.props.handleChange(usernameObj, true);

      let passwordObj = {
        target: {
          name: "password",
          value: password,
        },
      };
      this.props.handleChange(passwordObj, true);
    }
  };

  handleChange = (event) => {
    this.props.handleChange(event);
  };
  handleForgot = (event) => {
    console.log("inside handle logout");
    this.props.handleForgot(event);
  };

  render() {
    return (
      <div
        className={
          isMobile ? "NetWellPortalLoginMobile" : "NetWellPortalLoginDesktop"
        }
      >
        {/* <CssBaseline/> */}
        {/* <Container> */}
        <Card className="login-card">
          <Grid container>
            <Grid item xs={2} md={2}>
              <div
                className="logo-main"
                style={{ marginLeft: isMobile ? "100%" : " " }}
              >
                <img
                  alt="logo"
                  className="logo-customLogin"
                  src={require("./images/NetWell_Logo_Color.png")}
                />
              </div>
            </Grid>
          </Grid>
          {isMobile ? (
            <div className="familylogo-customMob">
              {/* <img  alt="logo" className="familylogo-custom" src={require('./images/family-looking-at-tablet.jpeg')} /> */}
            </div>
          ) : (
            <div className="familylogo-custom">
              {/* <img  alt="logo" className="familylogo-custom" src={require('./images/family-looking-at-tablet.jpeg')} /> */}
            </div>
          )}
          <form
            onSubmit={this.handleSubmit.bind(this)}
            className="main-form-login"
          >
            {/* <h4 className="label-head">Sign in with your email and password</h4> */}
            <p
              className="a-errorMessage"
              hidden={this.props.errorMsg.length <= 0}
            >
              {this.props.errorMsg}
            </p>
            <Grid container style={{ justifyContent: "center" }}>
              <div className="a-form-ctrl">
                {/* <p className="">Email</p> */}
                <input
                  ref={this.emailInput}
                  className={isMobile ? "a-input-loginMob" : "a-input-login"}
                  type="text"
                  placeholder="Enter your email"
                  name="username"
                  required
                  onChange={this.handleChange.bind(this)}
                />
              </div>

              <div
                className="a-form-ctrl"
                style={{ marginTop: isMobile ? "20px" : "" }}
              >
                {/* <p className="">Password</p> */}
                <input
                  ref={this.passwordInput}
                  className={isMobile ? "a-input-loginMob" : "a-input-login"}
                  type="password"
                  placeholder="Enter your password"
                  name="password"
                  required
                  onChange={this.handleChange.bind(this)}
                  style={{ marginBottom: isMobile ? "30px" : "" }}
                />
              </div>

              <div>
                <button
                  type="submit"
                  className={isMobile ? "a-btn2-loginMob" : "a-btn2-login"}
                  disabled={this.props.disableSiginBtn}
                >
                  Sign In
                </button>
                {/*<p style={{ textAlign: 'center' }}><span>Need an account?</span>&nbsp;
                <span className="forgot-pawd"><Link to="/signup">Sign Up</Link></span></p>*/}
              </div>
            </Grid>
          </form>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <span
              onClick={this.handleForgot.bind(this)}
              className="forgot-pawd"
            >
              Forgot your password?
            </span>
          </div>{" "}
          <br></br>
          {/* <div className="havingtrouble" style={{display:"flex", justifyContent: "center"}} >Having trouble logging in?</div>
              <div className="havingtrouble1" style={{display:"flex", justifyContent: "center"}}> Please check if your email has been registered on the portal.</div> */}
          {/* 

              <TableContainer component={Paper}>
                <Table aria-label="simple table">

                  <TableRow>
                    <TableCell>
                      <label for="email">
                        <b>Username</b>
                      </label></TableCell>
                    <TableCell>
                      <input
                        type="text"
                        placeholder="Enter Email"
                        name="username"
                        required
                        onChange={this.handleChange}
                      /></TableCell></TableRow>
                  <TableRow>
                    <TableCell>

                      <label for="psw">
                        <b>Password</b>
                      </label></TableCell>
                    <TableCell>
                      <input
                        type="text"
                        placeholder="Enter Password"
                        name="password"
                        required
                        onChange={this.handleChange}
                      /></TableCell></TableRow>
                  <TableRow>
                    <TableCell>


                      <button type="button" class="forgot" onClick={this.handleForgot} name="forgot">
                        forgot password
                </button></TableCell><TableCell>
                      <button type="submit" class="signupbtn">
                        Sign In
                </button></TableCell>
                  </TableRow>
                </Table>
              </TableContainer> */}
        </Card>
        {/* </Container> */}
      </div>
    );
  }
  closeModal() {
    this.props.closeModal();
    console.log("close modal");
  }
}
export default SignIn;
