import React, { Component } from "react";
import customeClasses from "./Eligibility.css";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "date-fns";
import axios from "axios";
import configuration from "../../../configurations";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Loader from "../../loader";
import customStyle from "../../../Assets/CSS/stylesheet_UHS";
import Sample from "../../CommonScreens/sampleTextField";
import i18n from "../../../i18next";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Fab from "@material-ui/core/Fab";
import InputLabel from "@material-ui/core/InputLabel";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const styles = (props) => customStyle.netWellEligiScreen;

const PurpleRadio = withStyles(customStyle.radioBtn)((props) => (
  <Radio color="default" {...props} />
));

const WizardButton = withStyles(customStyle.viewBtn)(Button);
const CustomFormControl = withStyles({
  root: {
    "& .MuiFormControl-fullWidth": {
      backgroundColor: "#e1e1e3",
      paddingLeft: "3px",
      paddingTop: 5,
    },
    "& .MuiSelect-select .MuiSelect-select": {
      paddingRight: "10px",
    },
    "& .MuiSelect-selectMenu": {
      height: "auto",
      overflow: "hidden",
      minHeight: "1.1876em",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    "& .MuiSelect-select": {
      cursor: "pointer",
      minWidth: "16px",
      userSelect: "none",
      borderRadius: 0,
      mozAppearance: "none",
      webkitAppearance: "none",
    },
    "& .MuiInputBase-input": {
      font: "inherit",
      color: "currentColor",
      width: "100%",
      border: 0,
      height: "1.1876em",
      margin: 0,
      display: "block",
      padding: "6px 0 7px",
      minWidth: 0,
      background: "none",
      boxSizing: "content-box",
      animationName: "mui-auto-fill-cancel",
      letterSpacing: "inherit",
      animationDuration: "10ms",
      webkitTapHighlightColor: "transparent",
      paddingLeft: 10,
    },
  },
})(FormControl);

const Languages = ["English", "Spanish", "Korean", "Vietnamese", "Other"];
const Ques = [
  {
    question: {
      id: 1001,
      question: "Enter the height and weight of each family member:",
      options: [],
    },
    options: [],
  },
  {
    question: {
      id: 1002,
      question: "Do you or any enrollee have hearing or vision loss?",
      options: [],
    },
    options: [],
    disease: [],
  },
  {
    question: {
      id: 1003,
      question:
        "Within the last 24 months, have you or any enrollee showed signs or symptoms, been treated by a health careprovider, at an urgent care, emergency room, or been hospitalized?",
    },
    options: [],
  },
  {
    question: {
      id: 1004,
      question: "Do you have any other medical coverage?",
    },
    options: [],
  },
  {
    question: {
      id: 1004,
      question:
        "In order to help us enhance your member experience in the future, please let us know what is your language preference?",
    },
    options: [],
  },
];

class Eligibility extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      progress: 1,
      isValid: true,
      radio: "",
      input: "",
      select: "",
      check: "",
      value: "",
      date: new Date(),
      questions: Ques,
      checkedList: [],
      selected: [],
      knockoutList: [],
      checkMember: [],
      questionData: [],
      membersList: [],
      knockOutList: [],
      loaderShow: false,
      name: "",
      disqual: [],
      allQueAnswred: false,
      submitValid: false,
      backValid: false,
      otherReason: "",
      formValid: false,
      errorText: "",
      isValidopt: false,
      isAgent: false,
      family: "",
      femaleList: [],
      isNextActive: false,
      syptomSelect: [],
      myCarrier: "",
      isGuardian: false,
    };
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    let subId = JSON.parse(localStorage.getItem("CurrentLoginUser")).id;
    axios
      .get(
        configuration.baseUrl +
          "/enrollment/saveCompletionStatus/" +
          subId +
          "/" +
          4,
      )
      .then((response) => {
        if (response && response.data.code === 200)
          console.log(response.data.code);
          this.setState({ isGuardian: response.data.response.isGuardian })
      })
      .catch((error) => console.log(error));

    window.scrollTo(0, 0);
    this._isMounted = true;
    let cookiesData = JSON.parse(sessionStorage.getItem("STATE_PARAM"));
    if (cookiesData && cookiesData.isAgent) {
      this.setState({
        isAgent: true,
      });
    }
    sessionStorage.setItem("current_screen", "4");

    this.setState({
      loaderShow: true,
      progress: this.state.progress + 14.28,
    });

    //get All members
    fetch(configuration.baseUrl + "/enrollment/getEnrollMemberBySubId/" + subId)
      .then((response) => response.json())
      .then((membersResult) => {
        if (membersResult.response) {
          if (this._isMounted) {
            this.setState(
              {
                membersList: membersResult.response,
                knockOutList: membersResult.response,
              },
              () => {
                let femaleList = this.state.knockOutList.map((val) => {
                  return val.gender == "FEMALE" ? val : [];
                });
                this.setState({
                  femaleList: femaleList,
                });
              },
            );
          }
        }
      });

    fetch(
      configuration.baseUrl +
        "/questionbank/getHealthQuestion/" +
        sessionStorage.getItem("CLIENT_ID") +
        "/HEALTH",
    )
      .then((response) => response.json())
      .then((response) => {
        axios
          .get(configuration.baseUrl + "/questionbank/getHealthAns/" + subId)
          .then((responseAns) => {
            if (responseAns && responseAns.data) {
              console.log("resp----", responseAns.data.response);
              this.setState({ loaderShow: false });
              let answers = [];
              let answerData = responseAns.data.response.questionAnswer
                ? responseAns.data.response.questionAnswer
                : [];

              if (answerData.length > 0) {
                let diseaseID = [];
                let knockOutData = [];
                for (let i = 0; i < answerData.length; i++) {
                  if (
                    (answerData[i].memberDiseaseList == [] ||
                      answerData[i].memberDiseaseList.length == 0) &&
                    answerData[i].answer !== "Yes" &&
                    answerData[i].answer !== "No"
                  ) {
                    knockOutData.push(answerData[i]);
                  } else {
                    diseaseID = diseaseID.concat(
                      answerData[i].memberDiseaseList,
                    );
                  }
                }
                let tempChecklist = [];

                for (var check = 0; check < diseaseID.length; check++) {
                  // tempChecklist[check] = diseaseID[check].diseaseId ? diseaseID[check].diseaseId:null
                  var derivedDisease;
                  if (
                    diseaseID[check].diseaseId != null ||
                    diseaseID[check].diseaseId != NaN
                  ) {
                    for (let r = 0; r < response.response.length; r++) {
                      let findID = response.response[r].disease.find(
                        (obj) => obj.diseaseId == diseaseID[check].diseaseId,
                      );
                      // diseaseID[check].diseaseId= parseInt(findID.id)
                      if (findID) {
                        derivedDisease = findID;
                        break;
                      }
                    }
                  }
                  tempChecklist.push(derivedDisease.diseaseId);

                  diseaseID[check].diseaseId = parseInt(derivedDisease.id);

                  let fname =
                    this.state.membersList &&
                    this.state.membersList.find(
                      (list) => list.id == parseInt(diseaseID[check].memberId),
                    );
                  diseaseID[check].firstName = fname.firstName;
                  diseaseID[check].lastName = fname.lastName;
                  diseaseID[check].id = fname.id;
                  diseaseID[check].disease = derivedDisease.diseaseId;
                }

                let knockOutList = [];
                for (
                  var knockVar = 0;
                  knockVar < knockOutData.length;
                  knockVar++
                ) {
                  var knockoutMemberIds =
                    knockOutData[knockVar].answer.split(",");
                  if (knockoutMemberIds.length > 0) {
                    for (
                      var memberIndex = 0;
                      memberIndex < knockoutMemberIds.length;
                      memberIndex++
                    ) {
                      var tempKnockOut =
                        this.state.membersList &&
                        this.state.membersList.find(
                          (list) => list.id == knockoutMemberIds[memberIndex],
                        );
                      if (tempKnockOut) {
                        var temp = Object.assign({}, tempKnockOut);
                        temp["questionId"] = knockOutData[knockVar].questionId;
                        knockOutList.push(temp);
                      }
                    }
                  }
                }

                this.setState(
                  {
                    checkedList: tempChecklist,
                    selected: diseaseID,
                    knockoutList: knockOutList,
                  },
                  () =>
                    console.log(
                      "selected===question desiesid",
                      this.state.selected,
                    ),
                );
              }
              let resultArray = [];
              for (let i = 0; i < response.response.length; i++) {
                let result = answerData
                  ? answerData.find(
                      (obj) =>
                        obj.questionId === response.response[i].question.id,
                    )
                  : {};
                let nam =
                  response.response[i].question &&
                  response.response[i].disease.map((key, index) => {
                    return key;
                  });
                let relatedQuestionAns = [];
                for (
                  let j = 0;
                  j < response.response[i].question.relatedQuestions.length;
                  j++
                ) {
                  let relatedSubQuestionAns = [];
                  let relatedQuestionsResult =
                    result && result.relatedQuestionAns
                      ? result.relatedQuestionAns.find(
                          (obj) =>
                            obj.relatedQuestionId ===
                            response.response[i].question.relatedQuestions[j]
                              .id,
                        )
                      : [];
                  for (
                    let k = 0;
                    k <
                    response.response[i].question.relatedQuestions[j]
                      .relatedSubQuestions.length;
                    k++
                  ) {
                    let relatedSubQuestionsResult =
                      relatedQuestionsResult &&
                      relatedQuestionsResult.relatedSubQuestionAns
                        ? relatedQuestionsResult.relatedSubQuestionAns.find(
                            (obj) =>
                              obj.relatedSubQuestionId ===
                              response.response[i].question.relatedQuestions[j]
                                .relatedSubQuestions[k].id,
                          )
                        : [];
                    let a = new Date();
                    relatedSubQuestionAns.push({
                      relatedSubQuestionId:
                        response.response[i].question.relatedQuestions[j]
                          .relatedSubQuestions[k].id,
                      answer:
                        relatedSubQuestionsResult &&
                        relatedSubQuestionsResult.answer
                          ? relatedSubQuestionsResult.answer
                          : response.response[i].question.relatedQuestions[j]
                                .relatedSubQuestions[k].subType === "date"
                            ? new Date()
                            : "",
                      optionId: relatedSubQuestionsResult
                        ? relatedSubQuestionsResult.optionId
                        : "",
                    });
                  }

                  let memArr =
                    relatedQuestionsResult && relatedQuestionsResult.answer
                      ? relatedQuestionsResult.answer.split(",")
                      : [];
                  let valArr = [];
                  if (memArr.length > 0) {
                    for (let i = 0; i < memArr.length; i++) {
                      let findIndex = this.state.membersList.findIndex(
                        (obj) => obj.id.toString() === memArr[i],
                      );
                      if (findIndex > -1) {
                        valArr.push(memArr[i]);
                      }
                    }
                  }

                  relatedQuestionAns.push({
                    relatedQuestionId:
                      response.response[i].question.relatedQuestions[j].id,
                    answer: valArr,
                    optionId: relatedQuestionsResult
                      ? relatedQuestionsResult.optionId
                      : "",
                    relatedSubQuestionAns: relatedSubQuestionAns,
                  });
                }
                let knockOutAns =
                  result && result.answer ? result.answer : "No";
                if (result) {
                  if (result.questionId == 1013 || result.questionId == 1014) {
                    knockOutAns = result.answer;
                  } else {
                    if (
                      result.answer != "Yes" &&
                      result.answer != "No" &&
                      result.answer != ""
                    ) {
                      knockOutAns = "Yes";
                    }
                  }
                }

                answers.push({
                  questionId: response.response[i].question.id,
                  optionId: result && result.optionId ? result.optionId : "",
                  answer: knockOutAns,
                  relatedQuestionAns: relatedQuestionAns,
                });
              }

              if (this._isMounted) {
                this.setState(
                  {
                    questions: response.response,
                    answerList: answers,
                    loaderShow: false,
                    bmiList: responseAns.data.response.bmiList,
                    symptoms: responseAns.data.response.symptoms,
                    myAnswers: responseAns.data.response.questionAnswer,
                    /*bmiData : BMI_DATA*/
                  },
                  () => {
                    if (this.state.bmiList) {
                      let newBMI = [];
                      this.state.bmiList.forEach((key) => {
                        newBMI = [...newBMI, key.feet, key.inches, key.weight];
                      });
                      if (newBMI.includes(null))
                        this.setState({ isNextActive: false });
                      else this.setState({ isNextActive: true });
                    }
                    responseAns.data.response.questionAnswer.map((val) => {
                      if (val.questionId == 1013 && val.answer != "No") {
                        this.setState({ myCarrier: val.answer });
                        this.state.answerList[3].answer = "Yes";
                      } else if (val.questionId == 1014) {
                        this.setState({ myLang: val.answer });
                      }
                    });
                    this.isAllValidHandler();
                    this.enableSubmit();

                    responseAns.data.response.symptoms.forEach((symp) => {
                      if (
                        symp.seen != null &&
                        symp.seen != "" &&
                        symp.visit != null &&
                        symp.visit != ""
                      ) {
                        this.state.answerList[2].answer = "Yes";
                        this.state.syptomSelect = [
                          ...this.state.syptomSelect,
                          symp.id,
                        ];
                      }
                    });
                  },
                );
              }
            }
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  reduceProgress = () => {
    if (this.state.count > 0) {
      this.setState(
        {
          count: this.state.count - 1,
          progress: (this.state.count / this.state.questions.length) * 100,
          isNextActive: true,
        },
        () => {
          this.isAllValidHandler();
          // this.isAllValidHandler('back','');

          this.enableSubmit();
        },
      );
    }
  };

  onClickBack = (event) => {
    let subId = JSON.parse(localStorage.getItem("CurrentLoginUser")).id;
    axios
      .get(configuration.baseUrl + "/questionbank/getHealthAns/" + subId)
      .then((response) => {
        console.log(
          "----------------------------resp= ",
          response.data.response,
        );
        for (
          var i = 0;
          i < response.data.response &&
          response.data.response.questionAnswer.length;
          i++
        ) {
          for (
            var j = 0;
            j <
            response.data.response.questionAnswer[i].memberDiseaseList.length;
            j++
          ) {
            this.setState(
              {
                answerList: response.data.response.questionAnswer[i].answer,
                checkedList:
                  response.data.response.questionAnswer[i].memberDiseaseList[j]
                    .diseaseId,

                selected:
                  response.data.response.questionAnswer[i].memberDiseaseList[j]
                    .memberId,
                bmiList: response.data.response.bmiList[i],
                symptoms: response.data.response.symptoms[i],
              },
              () => console.log("checkedList===", this.state.checkedList),
            );
          }
        }
      });

    for (let i = 0; i < this.state.answerList.length; i++) {
      for (
        let j = 0;
        j < this.state.answerList[i].relatedQuestionAns.length;
        j++
      ) {
        if (
          this.state.answerList[i].relatedQuestionAns[j].answer &&
          !Array.isArray(this.state.answerList[i].relatedQuestionAns[j].answer)
        ) {
          //this.state.answerList[i].relatedQuestionAns[j].answer = this.state.answerList[i].relatedQuestionAns[j].answer.toString();
          this.state.answerList[i].relatedQuestionAns[j].answer = this.state
            .answerList[i].relatedQuestionAns[j].answer
            ? this.state.answerList[i].relatedQuestionAns[j].answer.split(",")
            : [];
        }
      }
    }

    let count = 0;
    let progress = (count / this.state.questions.length) * 100 + 14.28;
    this.setState(
      {
        disqualified: false,
        count: count,
        progress: progress,
      },
      () => {
        // this.isAllValidHandler('back','');
        this.enableSubmit();
      },
    );
  };

  increaseProgress = () => {
    this.setState({
      loaderShow: false,
    });

    if (this.state.questions[this.state.count].question.code === "BMI") {
      this.handleContinue("NEXT", "BMI");
    } else if (
      this.state.questions[this.state.count].question.code === "Visit"
    ) {
      this.handleContinue("NEXT", "Visit");
    } else if (this.state.answerList[this.state.count].answer === "No") {
      // this.handleContinue('NEXT','');
      if (
        this.state.questions[this.state.count].question.code === "Knockout" ||
        this.state.questions[this.state.count].question.code === "SMO"
      ) {
        this.handleContinue("NEXT", "Knockout");
      } else {
        this.handleContinue("NEXT", "WaitingPeriod");
      }
    } else if (
      this.state.answerList[this.state.count].answer === "Yes" &&
      this.state.answerList[this.state.count].answer.length > 0
    ) {
      if (
        this.state.questions[this.state.count].question.code === "Knockout" ||
        this.state.questions[this.state.count].question.code === "SMO"
      ) {
        this.handleContinue("NEXT", "Knockout");
      } else {
        this.handleContinue("NEXT", "WaitingPeriod");
      }
    } else {
      if (
        this.state.questions[this.state.count].question.code === "Knockout" ||
        this.state.questions[this.state.count].question.code === "SMO"
      ) {
        this.handleContinue("NEXT", "Knockout");
      } else {
        this.handleContinue("NEXT", "WaitingPeriod");
      }
    }
  };

  selectKnockOut = (e, id, question, selected) => {
    let resultArray = this.state.knockoutList;
    if (e.target.checked) {
      //if checked (true), then add this id into checkedList
      let valIndex = resultArray.findIndex(
        (val) => val.id == id.id && val.questionId == question.id,
      );
      if (valIndex < 0) {
        var temp = Object.assign({}, id);
        temp["questionId"] = question.id;
        resultArray.push(temp);
      }
    } //if not checked (false), then remove this id from checkedList
    else {
      let valIndex = resultArray.findIndex(
        (val) => val.id == id.id && val.questionId == question.id,
      );
      if (valIndex >= 0) {
        resultArray.splice(valIndex, 1);
      }
    }
    this.setState({
      knockoutList: resultArray,
    });
  };

  isAllValidHandler = (name, value) => {
    let flag = false;
    if (
      this.state.answerList[this.state.count].answer !== "" &&
      this.state.answerList[this.state.count].answer === "Yes"
    ) {
      this.setState(
        {
          selected: this.state.selected,
          checkedList: this.state.checkedList,
          knockoutList: this.state.knockoutList,
          knockOutList: this.state.knockOutList,
        },
        () => {
          console.log("onBack===", this.state.selected);
        },
      );

      if (
        this.state.answerList[this.state.count].relatedQuestionAns[0] &&
        this.state.answerList[this.state.count].relatedQuestionAns[0]
          .relatedSubQuestionAns &&
        this.state.answerList[this.state.count].relatedQuestionAns[0]
          .relatedSubQuestionAns.length === 0
      ) {
        if (
          this.state.answerList[this.state.count].relatedQuestionAns[0].answer
            .length > 0
        ) {
          let data =
            this.state.answerList[this.state.count].relatedQuestionAns[0]
              .answer;
          var found;
          for (let i = 0; i < data.length; i++) {
            let ind = this.state.membersList.findIndex(
              (obj) => obj.id == data[i],
            );
            found = ind;
          }
          if ((found && found > 0) || found === 0) {
            flag = true;
          } else {
            flag = false;
          }
        } else {
          flag = false;
        }
      } else {
        let check = true;
        if (
          this.state.answerList[this.state.count].answer &&
          this.state.answerList[this.state.count].answer.length !== ""
        ) {
          check = true;
        } else {
          check = false;
        }
        flag = check;
      }
    } else if (this.state.answerList[this.state.count].answer === "") {
      flag = false;
    } else {
      flag = true;
    }
    if (this.state.count === this.state.questions.length - 1) {
      this.setState(
        {
          isValid: false,
        },
        () => {
          this.enableSubmit();
        },
      );
    } else {
      this.setState(
        {
          isValid: flag,
        },
        () => {
          this.enableSubmit();
        },
      );
    }
  };

  handlerCopy(e) {
    e.preventDefault();
  }

  textAnswerChangeHandler = (val, itemValid, parentDetails) => {
    if (itemValid) {
      this.state.answerList[this.state.count].relatedQuestionAns[
        parentDetails.index
      ].relatedSubQuestionAns[parentDetails.subindex].answer = val;

      this.setState(
        {
          refresh: true,
        },
        () => {
          this.isAllValidHandler(parentDetails.name, val);
        },
      );
    } else {
      this.state.answerList[this.state.count].relatedQuestionAns[
        parentDetails.index
      ].relatedSubQuestionAns[parentDetails.subindex].answer = "";

      this.setState(
        {
          refresh: true,
          isValid: false,
        },
        () => {
          this.enableSubmit();
        },
      );
    }
  };

  onDieseaseSelect = (e, id, symp) => {
    let resultArray = [];
    if (e.target.checked) {
      //if checked (true), then add this id into checkedList
      resultArray = this.state.checkedList.filter(
        (CheckedId) => CheckedId !== id,
      );
      resultArray.push(id);
    } //if not checked (false), then remove this id from checkedList
    else {
      resultArray = this.state.checkedList.filter(
        (CheckedId) => CheckedId !== id,
      );
      // resultArray.splice(id,1)

      let index = this.state.selected.findIndex((item) => item.disease == id);
      if (index >= 0) {
        this.state.selected.splice(index, 1);
      }
    }

    this.setState({
      checkedList: resultArray,
      selected: this.state.selected,
    });
  };

  onMemberSelect = (e, id) => {
    let resultArray = [];
    if (e.target.checked) {
      resultArray = this.state.syptomSelect.filter(
        (CheckedId) => CheckedId !== id,
      );
      resultArray.push(id);
      this.setState({
        isNextActive:
          this.state.symptoms.filter(
            (x) =>
              resultArray.indexOf(x.id) >= 0 &&
              (x.visit == "" ||
                x.visit == null ||
                x.seen == "" ||
                x.seen == null),
          ).length == 0,
      });
    } else {
      resultArray = this.state.syptomSelect.filter(
        (CheckedId) => CheckedId !== id,
      );
      // resultArray.splice(id,1)
      // this.state.symptoms.findIndex(x => x.id ===id);
      this.state.symptoms[
        this.state.symptoms.findIndex((x) => x.id === id)
      ].visit = null;
      this.state.symptoms[
        this.state.symptoms.findIndex((x) => x.id === id)
      ].seen = null;
      if (resultArray.length === 0) {
        this.setState({ isNextActive: false });
      } else
        this.setState({
          isNextActive:
            this.state.symptoms.filter(
              (x) =>
                resultArray.indexOf(x.id) >= 0 &&
                (x.visit == "" ||
                  x.visit == null ||
                  x.seen == "" ||
                  x.seen == null),
            ).length == 0,
        });
      let index = this.state.selected.findIndex((item) => item.disease == id);
      if (index >= 0) {
        this.state.selected.splice(index, 1);
      }
    }

    this.setState(
      {
        syptomSelect: resultArray,
        selected: this.state.selected,
      },
      () => {
        if (this.state.count == 3) {
          let newSymp = [];
          this.state.symptoms.forEach((key) => {
            if (this.state.syptomSelect.includes(key.id)) {
              newSymp = [...newSymp, key.seen, key.visit];
            }
          });
          if (
            newSymp.includes(null) ||
            newSymp.includes("") ||
            newSymp.includes(0)
          )
            this.setState({ isNextActive: false });
          else if (this.state.syptomSelect[0]) {
            this.state.symptoms.forEach((key) => {
              if (this.state.syptomSelect.includes(key.id)) {
                if (
                  key.seen == null ||
                  key.seen == "" ||
                  key.visit == null ||
                  key.visit == ""
                )
                  this.setState({ isNextActive: false });
                else this.setState({ isNextActive: true });
              }
            });
          } else this.setState({ isNextActive: false });
        }
      },
    );
  };

  answerChangeHandler = (event, name, optionId, code) => {
    if (name === "check") {
      this.state.answerList[this.state.count].answer = event.target.checked;
      this.state.answerList[this.state.count].optionId = optionId;
    } else if (
      (name === "radio" || name === "dropdown") &&
      (code == "WaitingPeriod" || code == "HealthQuestion")
    ) {
      this.state.answerList[this.state.count].answer = event.target.value;
      for (
        let i = 0;
        i < this.state.questions[this.state.count].options.length;
        i++
      ) {
        if (
          this.state.questions[this.state.count].options[i].option ===
          event.target.value
        ) {
          this.state.answerList[this.state.count].optionId =
            this.state.questions[this.state.count].options[i].id;
        }
      }

      if (event.target.value === "No") {
        this.state.answerList[this.state.count].answer = event.target.value;
        let resultArray = this.state.checkedList.filter(
          (CheckedId) =>
            CheckedId !== this.state.answerList[this.state.count].questionId,
        );
        let resultSelectedMember = this.state.selected.filter(
          (CheckedId) =>
            CheckedId !== this.state.answerList[this.state.count].questionId,
        );
        this.setState({
          checkedList: resultArray,
          selected: resultSelectedMember,
        });
      }
    } else if (
      (name === "radio" || name === "dropdown") &&
      (code == "Knockout" || code == "SMO")
    ) {
      this.state.answerList[this.state.count].answer = event.target.value;
      for (
        let i = 0;
        i < this.state.questions[this.state.count].options.length;
        i++
      ) {
        if (
          this.state.questions[this.state.count].options[i].option ===
          event.target.value
        ) {
          this.state.answerList[this.state.count].optionId =
            this.state.questions[this.state.count].options[i].id;
        }
      }

      if (event.target.value === "No") {
        this.state.answerList[this.state.count].answer = event.target.value;
        let resultArray = this.state.checkedList.filter(
          (CheckedId) =>
            CheckedId !== this.state.answerList[this.state.count].questionId,
        );
        let resultSelectedMember = this.state.selected.filter(
          (CheckedId) =>
            CheckedId !== this.state.answerList[this.state.count].questionId,
        );
        this.setState({
          checkedList: resultArray,
          selected: resultSelectedMember,
          knockoutList: this.state.knockoutList,
        });
      }
    } else {
      this.state.answerList[this.state.count].answer = event.target.value;
      this.state.answerList[this.state.count].optionId = optionId;
    }
    this.setState(
      {
        refresh: true,
      },
      () => {
        // this.isAllValidHandler();
        this.enableSubmit();
      },
    );
    if (this.state.answerList[this.state.count].answer == "Yes") {
      if (this.state.answerList[this.state.count].questionId == 1015) {
        if (this.state.syptomSelect[0]) {
          this.state.symptoms.forEach((key) => {
            if (this.state.syptomSelect.includes(key.id)) {
              if (
                key.seen == null ||
                key.seen == "" ||
                key.visit == null ||
                key.visit == ""
              )
                this.setState({ isNextActive: false });
              else
                this.setState({
                  isNextActive:
                    this.state.symptoms.filter(
                      (x) =>
                        this.state.syptomSelect.indexOf(x.id) >= 0 &&
                        (x.visit == "" ||
                          x.visit == null ||
                          x.seen == "" ||
                          x.seen == null),
                    ).length == 0,
                });
            }
          });
        } else this.setState({ isNextActive: false });
      } else if (this.state.answerList[this.state.count].questionId == 1013) {
        this.setState({ myCarrier: "" });
        if (this.state.answerList[this.state.count].answer == "Yes")
          this.setState({ isNextActive: false });
      }
    } else this.setState({ isNextActive: true });
  };

  submitAnswers = () => {
    this.setState({
      loaderShow: true,
    });
    axios
      .get(
        configuration.baseUrl +
          "/questionbank/saveNoMember/" +
          JSON.parse(localStorage.getItem("CurrentLoginUser")).id,
      )
      .then((response) => {
        if (this.state.questions[this.state.count].question.id == 1014) {
          this.handleContinue("SUBMIT", "HealthQuestion");
        } else if (this.state.answerList[this.state.count].answer === "No") {
          //  this.handleContinue('SUBMIT');
          if (
            this.state.questions[this.state.count].question.code ===
              "Knockout" ||
            this.state.questions[this.state.count].question.code === "SMO"
          ) {
            this.handleContinue("SUBMIT", "Knockout");
          } else {
            this.handleContinue("SUBMIT", "WaitingPeriod");
          }
        } else if (
          this.state.answerList[this.state.count].answer === "Yes" &&
          this.state.answerList[this.state.count].answer.length > 0
        ) {
          if (
            this.state.questions[this.state.count].question.code ===
              "Knockout" ||
            this.state.questions[this.state.count].question.code === "SMO"
          ) {
            this.handleContinue("SUBMIT", "Knockout");
          } else {
            this.handleContinue("SUBMIT", "WaitingPeriod");
          }
        }
      });
  };

  fourthMethod(e) {
    const re = /^[0-9]*$/;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  firstMethod(e) {
    const re = /^[a-zA-Z]*$/;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  textChangeHandler = (val, valid, details) => {
    console.log(val, "   ", valid, "    ", details, "  ", this.state.bmiList);

    if (details.name == "seen" || details.name == "visit") {
      this.state.symptoms[details.index][details.name] = val;

      this.setState({
        isNextActive:
          this.state.symptoms.filter(
            (x) =>
              this.state.syptomSelect.indexOf(x.id) >= 0 &&
              (x.visit == "" ||
                x.visit == null ||
                x.seen == "" ||
                x.seen == null),
          ).length == 0,
      });
    } else if (
      details.name == "feet" ||
      details.name == "inches" ||
      details.name == "weight"
    ) {
      this.state.bmiList[details.index][details.name] = val
        ? details.name == "weight"
          ? parseInt(val.slice(0, 3))
          : parseInt(val.slice(0, 2))
        : null;
    } else {
      this.state.bmiList[details.index][details.name] = val;
    }
    if (this.state.bmiList) {
      if (this.state.count == 0) {
        let newBMI = [];
        this.state.bmiList.forEach((key, i) => {
          newBMI = [...newBMI, key.feet, key.inches, key.weight];
        });
        let bmiListValidation = this.state.bmiList.find(x => x.feet <= 0 || x.weight <= 0 )
        if (newBMI.includes(null) || newBMI.includes("") || !valid || bmiListValidation)
          this.setState({ isNextActive: false });
        else this.setState({ isNextActive: true });
      } else if (this.state.count == 3) {
        let newSymp = [];
        this.state.symptoms.forEach((key) => {
          if (this.state.syptomSelect.includes(key.id)) {
            newSymp = [...newSymp, key.seen, key.visit];
          }
        });
        if (
          newSymp.includes(null) ||
          newSymp.includes("") ||
          newSymp.includes(0) ||
          !valid
        )
          this.setState({ isNextActive: false });
        else this.setState({ isNextActive: true });
      }
    }
    this.setState(
      {
        refresh: true,
      },
      () => {
        this.enableSubmit();
      },
    );
  };

  enableSubmit = () => {
    if (this.state.count !== this.state.questions.length) {
      if (
        this.state.answerList[this.state.count] &&
        this.state.answerList[this.state.count].answer
      ) {
        if (this.state.answerList[this.state.count].answer === "Yes") {
          console.log(
            "answerlist--",
            this.state.answerList[this.state.count].answer,
          );
          if (
            this.state.answerList[this.state.count].answer &&
            this.state.answerList[this.state.count].answer.length > 0
          ) {
            if (this.state.allQueAnswred) {
              console.log("validation submit--", this.state.allQueAnswred);
              this.setState({
                submitValid: false,
                backValid: false,
              });
              console.log("backfalse1");
            } else if (this.state.count === this.state.questions.length - 1) {
              this.setState({
                submitValid: false,
                backValid: false,
              });
            } else {
              console.log(
                "Else this.state.count === this.state.questions.length - 1",
                this.state.allQueAnswred,
              );
              this.setState({
                submitValid: true,
                backValid: false,
              });
            }
          } else {
            console.log("answerlist <0", this.state.allQueAnswred);

            this.setState({
              submitValid: true,
              backValid: true,
            });
          }
        } else if (this.state.answerList[this.state.count].answer !== "Yes") {
          if (this.state.allQueAnswred) {
            console.log("allQueAnswred <0", this.state.allQueAnswred);

            this.setState({
              submitValid: false,
              backValid: false,
            });
          } else if (this.state.count === this.state.questions.length - 1) {
            this.setState({
              submitValid: false,
              backValid: false,
            });
          } else {
            this.setState({
              submitValid: true,
              backValid: false,
            });
          }
        } else {
          this.setState({
            submitValid: true,
            backValid: true,
          });
        }
      } else {
        this.setState({
          submitValid: true,
          backValid: true,
        });
      }
    } else if (this.state.allQueAnswred) {
      this.setState({
        submitValid: true,
        backValid: false,
      });
    } else {
      this.setState({
        submitValid: true,
        backValid: true,
      });
    }
  };

  setLanguage = (e, que) => {
    this.setState({ myLang: e.target.value });
    this.state.answerList[this.state.count].answer = e.target.value;
    if (e.target.value == "") this.setState({ isNextActive: false });
    else this.setState({ isNextActive: true, isSubmit: true });
  };

  setCarrier = (e, que) => {
    this.setState({ myCarrier: e });
    // this.state.answerList[this.state.count].answer = e
    this.state.answerList[this.state.count].answer = "Yes";
    if (e == "") this.setState({ isNextActive: false });
    else this.setState({ isNextActive: true });
  };

  multiselectAnswerChangeHandler = (e, key, index, question) => {
    var id = key.id;
    const value = e.target.value;
    for (var i = 0; i < value.length; i++) {
      var tempObj = value[i];
      var index = value.findIndex(
        (val) =>
          tempObj.id == val.id && !val.diseaseId && tempObj.diseaseId == id,
      );
      if (index >= 0) {
        value.splice(index, 1);
        index = value.indexOf(tempObj);
        value.splice(index, 1);
        this.state.selected.splice(
          this.state.selected.findIndex((item) => item.disease == id),
        );
      }
    }

    this.state.selected = this.state.selected.filter(
      (item) => item.diseaseId != id,
    );

    var arr = [];
    for (var i = 0; i < value.length; i++) {
      var temp = Object.assign({}, value[i]);
      temp["diseaseId"] = id;
      temp["disease"] = key.diseaseId;
      temp["questionId"] = question.id;
      arr = arr.concat(temp);
    }
    this.setState(
      {
        selected: this.state.selected.concat(arr),
      },
      () => {
        console.log("selected===", this.state.selected);
      },
    );
  };

  handleContinue = (flag, code) => {
    console.log("flag- - - ", flag, "  ", code);
    this.setState({
      loaderShow: false,
    });
    let idObj;
    let subId = JSON.parse(localStorage.getItem("CurrentLoginUser")).id;
    if (this.state.isGuardian) {
      let groupId = JSON.parse(localStorage.getItem("CurrentLoginUser")).groupId;
      idObj = this.state.membersList.find((obj) => obj.groupId == groupId);
    } else {
      idObj = this.state.membersList.find((obj) => obj.subId == subId);
    }
    let obj = new Object();
    obj.subId = subId;
    obj.id = localStorage.getItem("memberId");
    obj.memberQuestionAnswers = [];
    let ans = JSON.parse(
      JSON.stringify(this.state.answerList[this.state.count]),
    );
    if (code == "Knockout" || code == "SMO") {
      if (ans.answer == "No") {
        ans.answer = ans.answer.toString();
      } else if (ans.answer == "Yes") {
        let tempArr = this.state.knockoutList.filter(
          (data) => data.questionId == ans.questionId,
        );
        if (tempArr) {
          ans.answer = tempArr.map((val) => {
            return val.id;
          });
          ans.answer = ans.answer.toString();
          console.log(
            "Knockout--",
            ans.answer.length == 1 ? ans.answer.split(",") : ans.answer,
          );
        }
      }
      // }
      ans.memberDiseaseList = [];
      obj.memberQuestionAnswers.push(ans);
    } else if (code == "WaitingPeriod" || code == "HealthQuestion") {
      for (let i = 0; i < ans.relatedQuestionAns.length; i++) {
        ans.relatedQuestionAns[i].answer =
          ans.relatedQuestionAns[i].answer.toString();
      }
      if (ans.answer == "No") {
        ans.answer = ans.answer.toString();
        ans.memberDiseaseList = [];
        obj.memberQuestionAnswers.push(ans);
      } else if (ans.questionId == 1013) {
        ans.answer = this.state.myCarrier;
        ans.memberDiseaseList = [];
        obj.memberQuestionAnswers.push(ans);
      } else if (ans.answer == "Yes") {
        ans.memberDiseaseList = [];
        this.state.selected &&
          this.state.selected.forEach((item) => {
            if (item.questionId == ans.questionId) {
              ans.memberDiseaseList.push({
                diseaseId: item.disease,
                memberId: item.id,
              });
            }
          });
        obj.memberQuestionAnswers.push(ans);
      } else {
        ans.answer = ans.answer.toString();
        ans.memberDiseaseList = [];
        obj.memberQuestionAnswers.push(ans);
      }
    }
    let count = 0;
    count = this.state.count + 1;
    if (flag === "SUBMIT") {
      if (this._isMounted) {
        this.props.onClick();
        // this.checkQualified();
      }
    }
    if (flag === "NEXT" || flag === "BUTTON") {
      console.log("--sub");
      this.setState(
        {
          count: this.state.count + 1,
          progress:
            ((this.state.count + 1) / this.state.questions.length) * 100,
          loaderShow: false,
        },
        () => {
          this.isAllValidHandler();
          // this.state.selected=[]
          this.enableSubmit();
          if (this.state.count == 0) {
            this.setState({ isNextActive: true });
          } else if (
            this.state.answerList[this.state.count].answer == "No" &&
            this.state.answerList[this.state.count].questionId == 1014
          ) {
            this.setState({ isSubmit: false });
          } else if (
            this.state.answerList[this.state.count].answer != "No" &&
            this.state.answerList[this.state.count].questionId == 1014
          ) {
            this.setState({ isSubmit: true });
          } else if (
            this.state.answerList[this.state.count].answer == "Yes" &&
            this.state.answerList[this.state.count].questionId == 1013 &&
            this.state.myCarrier == ""
          ) {
            this.setState({ isNextActive: false });
          } else if (this.state.answerList[this.state.count].answer == "No") {
            this.setState({ isNextActive: true });
          } else if (
            this.state.answerList[this.state.count].questionId == 1015
          ) {
            if (this.state.syptomSelect[0]) {
              this.state.symptoms.forEach((key) => {
                if (this.state.syptomSelect.includes(key.id)) {
                  if (
                    key.seen == null ||
                    key.seen == "" ||
                    key.visit == null ||
                    key.visit == ""
                  )
                    this.setState({ isNextActive: false });
                  else
                    this.setState({
                      isNextActive:
                        this.state.symptoms.filter(
                          (x) =>
                            this.state.syptomSelect.indexOf(x.id) >= 0 &&
                            (x.visit == "" ||
                              x.visit == null ||
                              x.seen == "" ||
                              x.seen == null),
                        ).length == 0,
                    });
                }
              });
            } else this.setState({ isNextActive: false });
          } else {
            this.setState({ isNextActive: true });
          }
        },
      );
    }

    if (code == "BMI") {
      axios
        .post(
          configuration.baseUrl + "/questionbank/saveMemberBMIList",
          this.state.bmiList,
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => console.log(error));
    } else if (code == "Visit") {
      if (ans.answer != "No") {
        axios
          .post(
            configuration.baseUrl + "/questionbank/saveSymptoms",
            this.state.symptoms,
          )
          .then((response) => {})
          .catch((error) => console.log(error));
      }
      ans.answer = ans.answer.toString();
      ans.memberDiseaseList = [];
      obj.memberQuestionAnswers.push(ans);
      axios
        .post(
          configuration.baseUrl + "/questionbank/saveMemberEligibiltyAnswer",
          obj,
        )
        .then((response) => {
          if (response.data.code === 200) {
            let count = 0;
          }
        })
        .catch((error) => {
          this.setState({
            loaderShow: false,
          });
        });
    } else {
      axios
        .post(
          configuration.baseUrl + "/questionbank/saveMemberEligibiltyAnswer",
          obj,
        )
        .then((response) => {
          if (response.data.code === 200) {
            let count = 0;
          }
        })
        .catch((error) => {
          this.setState({
            loaderShow: false,
          });
        });
    }
  };

  handleQuite = (code) => {
    this.setState({
      loaderShow: false,
    });
    let idObj;
    let subId = JSON.parse(localStorage.getItem("CurrentLoginUser")).id;
    if (this.state.isGuardian) {
      let groupId = JSON.parse(localStorage.getItem("CurrentLoginUser")).groupId;
      idObj = this.state.membersList.find((obj) => obj.groupId == groupId);
    } else {
      idObj = this.state.membersList.find((obj) => obj.subId == subId);
    }
    let obj = new Object();
    obj.subId = subId;
    obj.id = localStorage.getItem("memberId");
    obj.memberQuestionAnswers = [];

    let ans = JSON.parse(
      JSON.stringify(this.state.answerList[this.state.count]),
    );
    if (code == "Knockout" || code == "SMO") {
      for (let i = 0; i < ans.relatedQuestionAns.length; i++) {
        ans.relatedQuestionAns[i].answer = this.state.membersList.map((val) => {
          return val.id;
        });
      }
      ans.memberDiseaseList = [];

      obj.memberQuestionAnswers.push(ans);
    } else if (code == "WaitingPeriod" || code == "HealthQuestion") {
      for (let i = 0; i < ans.relatedQuestionAns.length; i++) {
        ans.relatedQuestionAns[i].answer =
          ans.relatedQuestionAns[i].answer.toString();
      }

      ans.memberDiseaseList = [];

      this.state.selected &&
        this.state.selected.forEach((item) => {
          ans.memberDiseaseList.push({
            diseaseId: item.diseaseId,
            memberId: item.id.toString(),
          });
        });
      obj.memberQuestionAnswers.push(ans);
    }

    this.setState(
      {
        loaderShow: true,
      },
      // this.checkQualified
    );
    axios
      .post(
        configuration.baseUrl + "/questionbank/saveMemberEligibiltyAnswer",
        obj,
      )
      .then((response) => {
        if (response.data.code === 200) {
          this.setState(
            {
              loaderShow: true,
              // disQuailifyModal: false
            },
            // this.checkQualified
          );
        }
      })
      .catch((error) => {
        this.setState({
          loaderShow: false,
        });
      });
  };

  handleSetupFamily = () => {
    this.setState({
      loaderShow: false,
    });
  };

  hideModal = () => {
    this.setState({
      loaderShow: false,
    });
  };

  handleSetFamily = () => {
    this.props.jumpsetupfamily();
  };

  handleExit = () => {
    this.props.jumptoexit();
  };

  otherOptingReasonChangeHandler = (event, name) => {
    event.preventDefault();
    let txtVal = event.target.value;

    if (txtVal !== "") {
      if (txtVal.match(/^[a-zA-Z ]*$/)) {
        this.setState({
          errorText: "",
          isValidopt: false,
          otherReason: txtVal,
          formValid: false,
        });
      } else {
        this.setState({
          errorText: "Please enter valid reason",
          isValidopt: true,
          otherReason: txtVal,
          formValid: true,
        });
      }
    } else {
      this.setState({
        errorText: "",
        isValidopt: false,
        otherReason: txtVal,
        formValid: false,
      });
    }
  };

  optingReasonChangeHandler = (event, name) => {
    let value = event.target.value;
    this.state[name] = value;
  };

  handlefamilyChange = (event) => {
    this.setState({ family: event.target.value });
  };

  render() {
    const { classes } = this.props;
    let relatedSubQuestions = this.state.questions[this.state.count].question;
    let answerField, finishButton;
    if (
      this.state.questions[this.state.count] &&
      this.state.questions[this.state.count].question.type === "radio"
    ) {
      answerField = (
        <div>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            style={{ display: "block" }}
            value={
              this.state.answerList[this.state.count].answer != "No"
                ? "Yes"
                : "No"
            }
            onChange={(event) =>
              this.answerChangeHandler(
                event,
                "radio",
                "",
                relatedSubQuestions.code,
              )
            }
          >
            {this.state.questions[this.state.count].options.map(
              (key, index) => (
                <FormControlLabel
                  key={index}
                  value={key.option}
                  control={<PurpleRadio />}
                  label={key.option}
                />
              ),
            )}
          </RadioGroup>
        </div>
      );
    }

    finishButton = (
      <WizardButton
        disabled={this.state.submitValid || !this.state.isSubmit}
        variant="contained"
        style={customeClasses.finishButton}
        onClick={this.submitAnswers}
      >
        {i18n.t("BUTTON.SUBMIT")}
      </WizardButton>
    );

    let currScreen;
    let relatedQuestions =
      this.state.questions[this.state.count].disease &&
      this.state.questions[this.state.count].disease
        ? this.state.questions[this.state.count].disease
        : [];
    // let relatedSubQuestions = this.state.questions[this.state.count].question && this.state.questions[this.state.count].question.relatedQuestions ? this.state.questions[this.state.count].question.relatedQuestions : [];
    let disableButton = true;
    let dataCheckList = [];
    if (this.state.count === 1) {
      if (this.state.questions && this.state.questions.length > 2)
        this.state.questions[this.state.count].disease.map((val) => {
          if (this.state.checkedList.find((x) => x === val.diseaseId)) {
            dataCheckList.push(val.diseaseId);
          }
        });
      if (
        this.state.answerList[this.state.count] &&
        this.state.answerList[this.state.count].answer === "Yes"
      ) {
        disableButton = false;

        try {
          dataCheckList.forEach((x) => {
            if (
              this.state.selected.filter((val) => val.disease === x).length ===
              0
            ) {
              disableButton = false;
              throw new Error("Break the loop.");
            } else {
              disableButton = true;
            }
          });
        } catch (error) {}
      }
    } else {
      disableButton = true;
    }
    return (
      <div style={customeClasses.wizContainer}>
        {this.state.loaderShow ? <Loader></Loader> : ""}
        <div style={customStyle.w100}>
          <div>
            <div>
              <Grid container>
                <Grid item xs={12}>
                  <Typography component="p" style={customeClasses.rowText}>
                    <p>
                      Please answer all questions after reviewing the
                      instructions and terms shared below.
                    </p>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <LinearProgress
                    variant="determinate"
                    classes={{
                      colorPrimary: classes.colorPrimary,
                      barColorPrimary: classes.barColorPrimary,
                    }}
                    style={classes.progress}
                    value={this.state.progress}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={customeClasses.questionTitleText}
                  >
                    <b>Medical Info</b>
                  </Typography>
                </Grid>
              </Grid>

              <div>
                <div>
                  {/* =================Question==================== */}

                  <Grid container style={{ alignItems: "center" }}>
                    <Grid item xs={12} md={8} lg={7}>
                      <div style={customeClasses.questionText}>
                        {this.state.questions[this.state.count].question
                          .type === "form" ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                this.state.questions[this.state.count].question
                                  .question,
                            }}
                          />
                        ) : this.state.questions[this.state.count].question
                            .question ? (
                          this.state.questions[
                            this.state.count
                          ].question.question.replace("SHOW DATE", new Date())
                        ) : (
                          ""
                        )}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      lg={2}
                      style={{ marginLeft: "15px" }}
                    >
                      <div style={customeClasses.answerText}>{answerField}</div>
                    </Grid>
                  </Grid>

                  {this.state.questions[this.state.count].question.type ===
                    "radio" &&
                  this.state.questions[this.state.count].question.code ===
                    "BMI" ? (
                    this.state.bmiList.map((key, i) => (
                      <Grid
                        xs={12}
                        sm={10}
                        md={9}
                        lg={9}
                        xl={4}
                        item={true}
                        key={i}
                        className="BMIDiv"
                      >
                        <Grid
                          xs={12}
                          sm={4}
                          md={3}
                          lg={2}
                          xl={2}
                          className="BMIMemberName"
                        >
                          {this.state.membersList.map((member) => {
                            if (key.id === member.id) {
                              return (
                                <div>
                                  {`${(member.prefix || "")} ${(member.firstName || "")} ${(member.middleName || "")} ${(member.lastName || "")} ${(member.suffix || "")}`}
                                </div>
                              );
                            }
                          })}
                        </Grid>
                        <Grid
                          xl={10}
                          lg={7}
                          md={9}
                          sm={9}
                          xs={11}
                          item={true}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Grid
                            xl={5}
                            lg={5}
                            md={7}
                            sm={7}
                            xs={8}
                            item={true}
                            className="heightDiv"
                          >
                            <Grid
                              xl={5}
                              lg={5}
                              md={5}
                              sm={5}
                              xs={5}
                              className="EnrollNew2Height"
                            >
                              <Sample
                                setChild={this.textChangeHandler.bind(this)}
                                type={"number"}
                                reqFlag={true}
                                name={"feet"}
                                label={"Height (feet)"}
                                value={key.feet}
                                disable={false}
                                style={customStyle.textFieldWrp33}
                                length={2}
                                fieldType={"feet"}
                                errMsg={"Enter valid height"}
                                helperMsg={"Height required"}
                                parentDetails={{
                                  name: "feet",
                                  index: i,
                                  id: key.id,
                                }}
                              ></Sample>
                            </Grid>
                            <Grid
                              xl={5}
                              lg={5}
                              md={5}
                              sm={5}
                              xs={5}
                              className="EnrollNew2Height"
                            >
                              <Sample
                                setChild={this.textChangeHandler.bind(this)}
                                type={"number"}
                                reqFlag={true}
                                name={"inches"}
                                label={"Height (in.)"}
                                value={key.inches}
                                disable={false}
                                style={customStyle.textFieldWrp11}
                                length={2}
                                fieldType={"inches"}
                                errMsg={"Enter valid height"}
                                helperMsg={"Height required"}
                                parentDetails={{
                                  name: "inches",
                                  index: i,
                                  id: key.id,
                                }}
                              ></Sample>
                            </Grid>
                          </Grid>
                          <Grid
                            xl={3}
                            lg={3}
                            md={4}
                            sm={4}
                            xs={4}
                            className="EnrollNew2Weight"
                          >
                            <Sample
                              setChild={this.textChangeHandler.bind(this)}
                              type={"number"}
                              reqFlag={true}
                              name={"weight"}
                              label={"Weight (lbs.)"}
                              value={key.weight}
                              disable={false}
                              style={customStyle.textFieldWrp22}
                              length={3}
                              fieldType={"num"}
                              errMsg={"Enter valid weight"}
                              helperMsg={"Weight required"}
                              parentDetails={{
                                name: "weight",
                                index: i,
                                id: key.id,
                              }}
                            ></Sample>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))
                  ) : this.state.questions[this.state.count].question.type ===
                      "radio" &&
                    this.state.questions[this.state.count].question.code ===
                      "Visit" &&
                    this.state.answerList[this.state.count].answer == "Yes" ? (
                    this.state.symptoms.map((key, i) => (
                      <Grid className="visitDiv">
                        <Grid item xs={12} sm={4} md={3} lg={2} xl={1}>
                          <FormControl
                            component="fieldset"
                            style={{
                              flexDirection: "row",
                              width: "80%",
                              alignItems: "center",
                            }}
                          >
                            <FormGroup style={{ marginTop: "33px" }}>
                              {this.state.membersList.map((member) => {
                                if (key.id === member.id) {
                                  return (
                                    <FormControlLabel
                                      style={{
                                        marginBottom: "7px",
                                        marginLeft: "7px",
                                      }}
                                      control={
                                        <Checkbox
                                          checked={
                                            this.state.syptomSelect.indexOf(
                                              member.id,
                                            ) >= 0
                                          }
                                          value={member.id}
                                          onChange={(e) =>
                                            this.onMemberSelect(e, member.id)
                                          }
                                          style={{
                                            color: "#533278",
                                            padding: "0px",
                                          }}
                                        ></Checkbox>
                                      }
                                      label={
                                        `${(member.prefix || "")} ${(member.firstName || "")} ${(member.middleName || "")} ${(member.lastName || "")} ${(member.suffix || "")}`
                                      }
                                    />
                                  );
                                }
                              })}
                            </FormGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={8} xl={10}>
                          {this.state.syptomSelect.indexOf(key.id) >= 0 && (
                            <FormControl fullWidth style={{ width: "100%" }}>
                              {this.state.selected.filter(
                                (obj) => obj.diseaseId == key.id,
                              ).length == 0 ? (
                                <Grid
                                  xl={5}
                                  lg={8}
                                  md={11}
                                  sm={12}
                                  xs={12}
                                  key={key}
                                  className="visitContainer"
                                >
                                  <Grid
                                    xl={2}
                                    lg={3}
                                    md={8}
                                    sm={5}
                                    xs={6}
                                    item={true}
                                    style={customStyle.EnrollNew2Visit}
                                  >
                                    <Sample
                                      setChild={this.textChangeHandler.bind(
                                        this,
                                      )}
                                      reqFlag={true}
                                      name={"visit"}
                                      label={"When was the visit?"}
                                      value={key.visit}
                                      disable={false}
                                      style={customStyle.textFieldWrp33}
                                      fieldType={"visit"}
                                      errMsg={"Enter valid text"}
                                      helperMsg={"Answer required"}
                                      parentDetails={{
                                        name: "visit",
                                        index: i,
                                        id: key.id,
                                      }}
                                    ></Sample>
                                  </Grid>
                                  <Grid
                                    xl={2}
                                    lg={3}
                                    md={10}
                                    sm={5}
                                    xs={6}
                                    style={customStyle.EnrollNew2Seen}
                                    className="seenField"
                                  >
                                    <Sample
                                      setChild={this.textChangeHandler.bind(
                                        this,
                                      )}
                                      reqFlag={true}
                                      name={"seen"}
                                      label={"What were they seen for?"}
                                      value={key.seen}
                                      disable={false}
                                      style={customStyle.textFieldWrp35}
                                      fieldType={"carrier"}
                                      errMsg={"Enter valid text"}
                                      helperMsg={"Answer required"}
                                      parentDetails={{
                                        name: "seen",
                                        index: i,
                                        id: key.id,
                                      }}
                                    ></Sample>
                                  </Grid>
                                </Grid>
                              ) : (
                                ""
                              )}
                            </FormControl>
                          )}
                        </Grid>
                      </Grid>
                    ))
                  ) : // this.state.symptoms.map((key, i) => (
                  //     <Grid xs={12} item={true} key={key} style={{ width: '60%', display: 'flex', flexDirection: 'row' }}>
                  //         <Grid xs={3} item={true} style={customStyle.bmiName}>
                  //             {this.state.membersList.map(member => {
                  //                 if (key.id === member.id) {
                  //                     return (
                  //                         <div>
                  //                             {member.firstName + ' ' + member.lastName}
                  //                         </div>
                  //                     )
                  //                 }
                  //             })}
                  //         </Grid>
                  //         <Grid xs={4} item={true} style={{ display: 'flex', flexDirection: 'row' }}>
                  //             <div style={customStyle.EnrollNew2Height}>
                  //                 <Sample setChild={this.textChangeHandler.bind(this)} reqFlag={true} name={'visit'} label={'When was the visit?'} value={key.visit} disable={false} style={customStyle.textFieldWrp33} fieldType={'visit'} errMsg={'Enter valid text'} helperMsg={'Answer required'} parentDetails={{ name: 'visit', index: i, id: key.id }}></Sample>
                  //             </div>
                  //         </Grid>
                  //         <Grid xs={4} item={true}>
                  //             <div style={customStyle.EnrollNew2Weight}>
                  //                 <Sample setChild={this.textChangeHandler.bind(this)} reqFlag={true} name={'seen'} label={'What were they seen for?'} value={key.seen} disable={false} style={customStyle.textFieldWrp22} fieldType={'carrier'} errMsg={'Enter valid text'} helperMsg={'Answer required'} parentDetails={{ name: 'seen', index: i, id: key.id }}></Sample>
                  //             </div>
                  //         </Grid>
                  //     </Grid>
                  // ))
                  this.state.questions[this.state.count].question.type ===
                      "radio" &&
                    this.state.questions[this.state.count].question.code ===
                      "Coverage" &&
                    this.state.answerList[this.state.count] &&
                    this.state.answerList[this.state.count].answer !== "No" ? (
                    <Grid
                      xs={6}
                      item={true}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        margin: "-20px 0px -90px 0px",
                      }}
                    >
                      <div style={customStyle.EnrollNew2Carrier}>
                        <Sample
                          setChild={(e) =>
                            this.setCarrier(
                              e,
                              this.state.questions[this.state.count].question,
                            )
                          }
                          reqFlag={true}
                          name={"carrier"}
                          label={"What is the name of the carrier?"}
                          value={this.state.myCarrier}
                          disable={false}
                          style={customStyle.textFieldWrp34}
                          fieldType={"carrier"}
                          errMsg={"Enter valid text"}
                          helperMsg={"Answer required"}
                          parentDetails={{ name: "carrier" }}
                        ></Sample>
                      </div>
                    </Grid>
                  ) : this.state.questions[this.state.count].question.type ===
                      "radio" &&
                    this.state.questions[this.state.count].question.code ===
                      "Languages" &&
                    this.state.answerList[this.state.count] ? (
                    <Grid
                      xs={12}
                      item={true}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <FormControl fullWidth>
                        {!this.state.myLang && (
                          <InputLabel
                            id="demo-simple-select-label"
                            style={{
                              color: "#000",
                              zIndex: 1,
                              top: "19px",
                              left: "10px",
                            }}
                          >
                            Select language*
                          </InputLabel>
                        )}
                        <Select
                          style={{
                            marginTop: "24px",
                            lineHeight: "32px",
                            width: "300px",
                            height: "56px",
                            backgroundColor: "#f1f1f1",
                            paddingLeft: "10px",
                          }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Select language"
                          value={this.state.myLang ? this.state.myLang : null}
                          onChange={(e) =>
                            this.setLanguage(
                              e,
                              this.state.questions[this.state.count].question,
                            )
                          }
                          // renderValue={this.state.myLang}
                          // disableUnderline={false}
                          required={true}
                        >
                          {Languages.map((lang) => (
                            <MenuItem value={lang} key={1001 + "_" + lang}>
                              {lang}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : null}

                  {/* ===================List====================== */}
                  {relatedSubQuestions.code == "HealthQuestion" && (
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={8}
                        lg={7}
                        style={customeClasses.questionSubtext}
                      >
                        {this.state.questions[this.state.count].disease ? (
                          <div style={{ height: 98, minWidth: "fit-content" }}>
                            {relatedSubQuestions.code == "WaitingPeriod" ||
                            relatedSubQuestions.code == "HealthQuestion" ? (
                              this.state.questions[this.state.count].question &&
                              this.state.questions[this.state.count].disease ? (
                                this.state.questions[
                                  this.state.count
                                ].disease.map((key, index) =>
                                  // <li className="listStyle" key={index}>{key}</li>
                                  this.state.answerList[this.state.count] &&
                                  this.state.answerList[this.state.count]
                                    .answer === "Yes" ? (
                                    <div
                                      style={
                                        customeClasses.questionSubtextColor1
                                      }
                                    >
                                      <CustomFormControl
                                        component="fieldset"
                                        style={{
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                          width: "100%",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Grid item xs={12} md={6} lg={6}>
                                          <FormGroup>
                                            <FormControlLabel
                                              style={{ marginBottom: "7px" }}
                                              control={
                                                <Checkbox
                                                  checked={
                                                    this.state.checkedList.indexOf(
                                                      key.diseaseId,
                                                    ) >= 0
                                                  }
                                                  // onClick={(event) => this.dieseaseCheck(event)}
                                                  value={key.disease}
                                                  onChange={(e) =>
                                                    this.onDieseaseSelect(
                                                      e,
                                                      key.diseaseId,
                                                    )
                                                  }
                                                  // inputProps={{
                                                  //     'aria-label': 'secondary checkbox',
                                                  // }}
                                                  style={{
                                                    color: "#533278",
                                                    padding: "0px",
                                                    marginRight: "8px",
                                                  }}
                                                ></Checkbox>
                                              }
                                              label={key.disease}
                                            />
                                          </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                          {this.state.checkedList.indexOf(
                                            key.diseaseId,
                                          ) >= 0 && (
                                            <CustomFormControl
                                              fullWidth
                                              className="selectWidth"
                                            >
                                              {this.state.selected.filter(
                                                (obj) =>
                                                  obj.diseaseId == key.id,
                                              ).length == 0 ? (
                                                <InputLabel
                                                  id="demo-multiple-name-label"
                                                  style={{
                                                    color: "black",
                                                    transform:
                                                      "translate(0, 0px) scale(1)",
                                                    paddingLeft: 3,
                                                    paddingTop: 5,
                                                  }}
                                                >
                                                  Select Family Member*
                                                </InputLabel>
                                              ) : (
                                                ""
                                              )}
                                              <Select
                                                style={{
                                                  marginTop: "0px",
                                                  lineHeight: "0.5em",
                                                }}
                                                labelId="demo-multiple-name-label"
                                                multiple
                                                value={this.state.selected.filter(
                                                  (item) =>
                                                    item.diseaseId == key.id,
                                                )}
                                                onChange={(e) =>
                                                  this.multiselectAnswerChangeHandler(
                                                    e,
                                                    key,
                                                    index,
                                                    this.state.questions[
                                                      this.state.count
                                                    ].question,
                                                  )
                                                }
                                                renderValue={(selected) =>
                                                  selected
                                                    .map(
                                                      (val) =>
                                                        `${(val.prefix || "")} ${(val.firstName || "")} ${(val.middleName || "")} ${(val.lastName || "")} ${(val.suffix || "")}`
                                                    )
                                                    .join(", ")
                                                }
                                                disableUnderline={true}
                                              >
                                                {this.state.membersList.map(
                                                  (member) => (
                                                    <MenuItem
                                                      value={member}
                                                      key={
                                                        key.id + "_" + member.id
                                                      }
                                                    >
                                                      <Checkbox
                                                        style={{
                                                          color: "#533278",
                                                        }}
                                                        checked={
                                                          this.state.selected.filter(
                                                            (val) =>
                                                              val.id ===
                                                                member.id &&
                                                              val.diseaseId ==
                                                                key.id,
                                                          ).length !== 0
                                                        }
                                                      />
                                                        {`${(member.prefix || "")} ${(member.firstName || "")} ${(member.middleName || "")} ${(member.lastName || "")} ${(member.suffix || "")}`}
                                                    </MenuItem>
                                                  ),
                                                )}
                                              </Select>
                                            </CustomFormControl>
                                          )}
                                        </Grid>
                                      </CustomFormControl>
                                    </div>
                                  ) : (
                                    <div
                                      style={
                                        customeClasses.questionSubtextColor
                                      }
                                    >
                                      <li
                                        className="listStyle"
                                        key={index}
                                        style={{ lineHeight: "0px" }}
                                      >
                                        {key.disease}
                                      </li>
                                    </div>
                                  ),
                                )
                              ) : (
                                ""
                              )
                            ) : (relatedSubQuestions.code == "Knockout" ||
                                relatedSubQuestions.code == "SMO") &&
                              this.state.answerList[this.state.count] &&
                              this.state.answerList[this.state.count].answer ===
                                "Yes" ? (
                              <Grid container>
                                <Grid item xs={12} md={8} lg={8}>
                                  {/* <FormControl > */}

                                  <div style={customStyle.checkBoxStyle}>
                                    {this.state.knockOutList.map((key) =>
                                      this.state.knockOutList.length > 0 &&
                                      ((relatedSubQuestions.code ==
                                        "Knockout" &&
                                        key.gender == "FEMALE") ||
                                        relatedSubQuestions.code == "SMO") ? (
                                        <label
                                          key={key.id}
                                          value={key.id}
                                          style={{
                                            display: "block",
                                            marginBottom: "0px",
                                          }}
                                        >
                                          <Checkbox
                                            id={
                                              "Knockout" +
                                              key.id +
                                              this.state.questions[
                                                this.state.count
                                              ].question.id
                                            }
                                            inputProps={{
                                              "aria-label":
                                                "secondary checkbox",
                                            }}
                                            style={{ color: "#533278" }}
                                            checked={
                                              this.state.knockoutList.find(
                                                (val) =>
                                                  val.id == key.id &&
                                                  val.questionId ==
                                                    this.state.questions[
                                                      this.state.count
                                                    ].question.id,
                                              )
                                                ? true
                                                : false
                                            }
                                            // checked = {this.state.knockoutList.indexOf(key.id) >= 0}
                                            onChange={(event) =>
                                              this.selectKnockOut(
                                                event,
                                                key,
                                                this.state.questions[
                                                  this.state.count
                                                ].question,
                                                this.state.knockoutList.find(
                                                  (val) =>
                                                    val.id == key.id &&
                                                    val.questionId ==
                                                      this.state.questions[
                                                        this.state.count
                                                      ].question.id,
                                                )
                                                  ? true
                                                  : false,
                                              )
                                            }
                                          />
                                          <span
                                            style={{
                                              display: "block",
                                              marginLeft: "38px",
                                              marginTop: "-31px",
                                              fontFamily:
                                                "Roboto, Arial, Helvetica, sans-serif",
                                            }}
                                          >
                                            {`${(key.prefix || "")} ${(key.firstName || "")} ${(key.middleName || "")} ${(key.lastName || "")} ${(key.suffix || "")}`}
                                          </span>
                                        </label>
                                      ) : null,
                                    )}
                                  </div>

                                  {/* </FormControl>  */}
                                </Grid>
                              </Grid>
                            ) : null}
                          </div>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12} sm={8} md={8} lg={4}>
                        {this.state.answerList[this.state.count] &&
                        this.state.answerList[this.state.count].answer ===
                          "Yes" ? (
                          <>
                            <ArrowBackIcon />
                            {this.state.count === 3 ||
                            this.state.count === 4 ? (
                              <span>Please select member.</span>
                            ) : (
                              <span>
                                {" "}
                                Please select all applicable diagnoses,
                                <br />
                                <span style={{ margin: "6%" }}>
                                  then select member this applies to.
                                </span>
                              </span>
                            )}
                          </>
                        ) : null}
                      </Grid>
                    </Grid>
                  )}
                </div>
                {/* ----------------- */}

                {this.state.questions[this.state.count].question.instruction !==
                  "" && (
                  <div style={customeClasses.w30}>
                    <div style={customeClasses.subTitle}>
                      {i18n.t("ENROLL_NEW.SUB_TITLE")}
                    </div>
                    <div style={customeClasses.instTxt}>
                      {
                        this.state.questions[this.state.count].question
                          .instruction
                      }
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div style={customStyle.bottomMainConatiner}>
            <div style={customStyle.newBottomContainer}>
              <div style={customStyle.bottomChildContainer1}>
                {!this.state.disqualified && (
                  <Grid
                    container
                    xs={12}
                    sm={7}
                    md={9}
                    lg={7}
                    style={{ marginTop: "50px" }}
                  >
                    <Grid item xs={4} md={2} lg={2}>
                      <WizardButton
                        variant="contained"
                        style={
                          this.state.count === 0
                            ? customeClasses.disabledButton
                            : customeClasses.button
                        }
                        disabled={
                          this.state.count === 0 || this.state.backValid
                            ? true
                            : false
                        } /*((this.state.answerList[this.state.count].answer === 'Yes' && this.state.answerList[this.state.count].relatedQuestionAns[0].answer && this.state.answerList[this.state.count].relatedQuestionAns[0].answer.length > 0) )*/
                        onClick={this.reduceProgress}
                      >
                        {i18n.t("BUTTON.BACK")}
                      </WizardButton>
                    </Grid>
                    <Grid item xs={4} md={2} lg={2}>
                      <WizardButton
                        variant="contained"
                        style={
                          this.state.count ===
                            this.state.questions.length - 1 || !disableButton
                            ? customeClasses.disabledButton
                            : customeClasses.button
                        }
                        // disabled={(this.state.count === this.state.questions.length - 1) || (this.state.answerList[this.state.count].answer === 'Yes' &&
                        //     this.state.selected.length == 0)}
                        disabled={
                          this.state.count ===
                            this.state.questions.length - 1 ||
                          !this.state.isNextActive ||
                          !disableButton
                        }
                        onClick={this.increaseProgress}
                      >
                        {i18n.t("BUTTON.NEXT")}
                      </WizardButton>
                    </Grid>
                    <Grid item xs={4} md={2} lg={2}>
                      {finishButton}
                    </Grid>
                  </Grid>
                )}

                {/*((this.state.count === this.state.questions.length-1) && (this.state.answerList[this.state.count] && this.state.answerList[this.state.count].answer) && ((this.state.answerList[this.state.count].answer === 'Yes' && this.state.answerList[this.state.count].relatedQuestionAns[0].answer && this.state.answerList[this.state.count].relatedQuestionAns[0].answer.length > 0) || this.state.answerList[this.state.count].answer === 'No' )) ||
                 */}
              </div>
              <div style={customStyle.bottomChildContainer2}>
                {/*<div style={customStyle.chatContainer}>
                                <CrudButton  className={'purechat-button-expand'} color="primary" aria-label="add"  style={customStyle.QuickChatBtn}>
                                                    <ForumIcon style={{fontSize : '30px'}} />
                                        </CrudButton>
                                </div>*/}
              </div>
            </div>
            <div style={customStyle.newBottomContainer}>
              {/*<div style={customStyle.needHelpContainer}>*/}
              {/*        <div style={{ fontWeight: 'bold' }}>{i18n.t('ENROLL_FAMILY.HELP')}</div>*/}
              {/*        <div>{i18n.t('ENROLL_FAMILY.SUPPORT')}</div>*/}
              {/*</div>  */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Eligibility);
