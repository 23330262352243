// @media screen and (-webkit-min-device-pixel-ratio:0) { select { -webkit-appearance: menulist-button !important; line-height:24px !important; } }
// '&::-webkit-input-placeholder'= {
//     height: '90px',
//   }

const style = {
  textField: {
    width: "100%",
    /* width: '23%', */
    /*         height: '15.6%', */
    /* marginRight: '2.5%', */
    marginTop: "0",
    marginBottom: "0",
    marginLeft: "0",
    borderRadius: "4px",
  },
  ageTextField: {
    width: "100%",
    /*         width: '16%',
        height: '15.6%', */
    margin: "0",
    borderRadius: "4px",
  },
  dropDown: {
    width: "100%",
    marginTop: "0",
    marginBottom: "0",
    marginLeft: "0",
    borderRadius: "4px",
    // height: '56px',
    WebkitHeight: "90px",
  },
  crudIcon: {
    /* marginLeft: '30px', */
    width: "40px",
    height: "40px",
    marginRight: "29px",
    marginTop: "0",
    marginBottom: "0",
  },
  removeIcon: {
    /* marginLeft: '30px', */
    width: "40px",
    height: "40px",
    marginRight: "29px",
    marginTop: "0",
    marginBottom: "0",
  },
  addIcon: {
    width: "56px",
    height: "56px",
    // marginRight: '2.1%',
    marginRight: "1.5%",
    float: "right",
    marginBottom: "0",
    marginTop: "37px",
  },
  heading: {
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "16px",
    color: "rgba(0, 0, 0, 0.87)",
    marginTop: "20px",
    marginBottom: "10px",
    maxHeight: "250px",
  },
  headingGrid: {
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "16px",
    color: "rgba(0, 0, 0, 0.87)",
    marginTop: "20px",
    // marginBottom: '20px',
    maxHeight: "250px",
  },
  normalText: {
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
    fontSize: "14px",
    lineHeight: "16px",
    color: "rgba(0, 0, 0, 0.87)",
  },
};

export default style;
