const planConfig = [
  {
    CLIENT_ID: "5448",
    iframeURL: "https://www.netwell.com/netWell-standard-program-grid/",
  },
  {
    CLIENT_ID: "5449",
    iframeURL: "https://www.netwell.com/netWell-standard-program-grid/",
  },
  {
    CLIENT_ID: "5514",
    iframeURL: "https://www.netwell.com/netWell-standard-program-grid/",
  },
  {
    CLIENT_ID: "5515",
    iframeURL: "https://www.netwell.com/netWell-standard-program-grid/",
  },
  {
    CLIENT_ID: "4350",
    iframeURL: "https://www.netwell.com/netWell-afa-program-grid/",
  },
  {
    CLIENT_ID: "4429",
    iframeURL: "https://www.netwell.com/netWell-standard-246-program-grid/",
  },
  {
    CLIENT_ID: "4428",
    iframeURL: "https://www.netwell.com/netWell-standard-246-program-grid/",
  },
  {
    CLIENT_ID: "5446",
    iframeURL: "https://www.netwell.com/netWell-standard-246-program-grid/",
  },
  {
    CLIENT_ID: "5447",
    iframeURL: "https://www.netwell.com/netWell-standard-246-program-grid/",
  },
  {
    CLIENT_ID: "5450",
    iframeURL: "https://www.netwell.com/netWell-standard-246-program-grid/",
  },
  {
    CLIENT_ID: "5503",
    iframeURL: "https://www.netwell.com/netWell-standard-program-grid/",
  },
  {
    CLIENT_ID: "6548",
    iframeURL: "https://www.netwell.com/netWell-healthy-life-program-grid/",
  },
  {
    CLIENT_ID: "5558",
    iframeURL: "https://www.netwell.com/netWell-healthy-life-program-grid/",
  },
  {
    CLIENT_ID: "4364",
    iframeURL: "https://www.netwell.com/netWell-standard-program-grid/",
  },
  {
    CLIENT_ID: "5540",
    iframeURL: "https://www.netwell.com/netWell-healthy-life-program-grid/",
  },
  {
    CLIENT_ID: "5541",
    iframeURL:
      "https://www.netwell.com/netWell-community-healthshare-program-grid/",
  },
  {
    CLIENT_ID: "4377",
    iframeURL:
      "https://www.netwell.com/netWell-community-healthshare-program-grid/",
  },
  {
    CLIENT_ID: "5550",
    iframeURL: "https://www.netwell.com/netWell-standard-program-grid/",
  },
  {
    CLIENT_ID: "4378",
    iframeURL: "https://www.netwell.com/netWell-standard-program-grid/",
  },
  {
    CLIENT_ID: "5553",
    iframeURL: "https://www.netwell.com/netWell-standard-program-grid/",
  },
  {
    CLIENT_ID: "4379",
    iframeURL: "https://www.netwell.com/netWell-standard-program-grid/",
  },
  {
    CLIENT_ID: "5559",
    iframeURL: "https://www.netwell.com/netWell-standard-program-grid/",
  },
];

export default planConfig;
