import { fabric } from "fabric";
import Grid from "@material-ui/core/Grid";

import React, { Component } from "react";

window.mobileCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};
class SignScroll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canvas: null,
      initialImage: null,
      finalImage: null,
      canvasFinalWidth: 0,
      canvasFinalHeight: 0,
      w: "100%",
      h: "100%",
    };
  }

  componentDidMount() {
    if (this.state.canvas) {
      this.state.canvas.clear();
      this.state.canvas.dispose();
    }

    this.setState({ canvas: this.initCanvas() }, () => {
      this.setState({ initialImage: this.state.canvas.toDataURL() });
    });
  }

  disableCanvas = () => {
    const canvas = this.state.canvas;
    canvas.isDrawingMode = false;
    canvas.width = this.state.canvasFinalWidth;
    canvas.height = this.state.canvasFinalHeight;
    canvas.selection = false;
    //disable all events
    canvas.forEachObject((obj) => {
      obj.selectable = false;
      obj.evented = false;
    });
    this.setState({ canvas: canvas });
  };

  initCanvas = () => {
    const that = this;
    const canvas = new fabric.Canvas("signCanvas", {
      isDrawingMode: true,
      width: document.getElementById("canvasContainer").offsetWidth,
      height: document.getElementById("canvasContainer").offsetHeight,
    });

    that.setState({ canvas });

    canvas.on("path:created", function (event) {
      that.detectChanges();
    });

    return canvas;
  };

  detectChanges = () => {
    this.props.onCanvasChange();
  };

  clearCanvas = () => {
    this.state.canvas.clear();
  };

  convertCanvasToImage = () => {
    const canvas = document.getElementById("signCanvas");
    const image = new Image();
    image.src = canvas.toDataURL("image/png");
    return image;
  };

  downloadImageFromDataURL = () => {
    const link = document.createElement("a");
    link.download = "signature.png";
    link.href = this.state.finalImage;
    link.click();
  };

  cropImageFromCanvas = () => {
    if (window.mobileCheck()) {
      return;
    }
    const canvas = document.getElementById("signCanvas");
    const ctx = canvas.getContext("2d");
    let w = canvas.width;
    let h = canvas.height;
    let pix = { x: [], y: [] };
    let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    let x;
    let y;
    let index;

    for (y = 0; y < h; y++) {
      for (x = 0; x < w; x++) {
        index = (y * w + x) * 4;
        if (imageData.data[index + 3] > 0) {
          pix.x.push(x);
          pix.y.push(y);
        }
      }
    }
    pix.x.sort(function (a, b) {
      return a - b;
    });
    pix.y.sort(function (a, b) {
      return a - b;
    });
    let n = pix.x.length - 1;

    w = 1 + pix.x[n] - pix.x[0];
    h = 1 + pix.y[n] - pix.y[0];
    let cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);

    canvas.width = w;
    canvas.height = h;
    ctx.putImageData(cut, 0, 0);
    document
      .getElementById("signCanvas")
      .setAttribute("style", `width: ${w}px; height: ${h}px;`);

    this.setState({ canvas, w, h });
  };

  render() {
    return (
      <Grid
        sm={12}
        md={12}
        style={{
          textAlign: "-webkit-center",
          width: "100%",
          height: "100%",
          background: "#e1e1e3",
        }}
      >
        <div
          id="canvasContainer"
          style={{
            width: "100%",
            height: "100%",
            background: "#e1e1e3",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <canvas
            id="signCanvas"
            width="100%"
            height="100%"
            onMouseUp={this.detectChanges}
          />
        </div>
      </Grid>
    );
  }
}

export default SignScroll;
