const style = {
  root: {
    width: "100%",
  },
  button: {
    width: "100px",
    height: "40px",
    marginRight: "20px",
    marginBottom: "20px",
  },
  buttonCGoDashboard: {
    width: "auto",
    height: "40px",
    marginRight: "20px",
    marginBottom: "20px",
  },
  helpText: {
    color: "#304d63",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "right",
  },
  finishButton: {
    width: "104px",
    height: "40px",
    marginBottom: "20px",
  },
  questionText: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.87)",
    lineHeight: "16px",
    marginBottom: "5px",
    textAlign: "justify",
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
  },
  answerText: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.87)",
    lineHeight: "16px",
    marginBottom: "5px",
    marginTop: "5px",
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
  },
  questionTitleText: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.87)",
    lineHeight: "16px",
    marginBottom: "5px",
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
  },
  rowText: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.87)",
    lineHeight: "16px",
    marginBottom: "5px",
    textAlign: "justify",
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
  },
  progress: {
    width: "100%",
    marginTop: "24px",
    height: "10px",
    backgroundColor: "#f2f2f2",
    color: "#533278 !important",
    marginBottom: "34px",
  },
  bottomStyle: {
    marginTop: "34px",
    marginBottom: "15px",
    border: "1px solid red",
  },
  disabledButton: {
    backgroundColor: "#f0f0f1",
    color: "#b4b4bb",
    // color:'#ffffff',
    width: "100px",
    height: "40px",
    marginRight: "20px",
    marginBottom: "20px",
    cursor: "pointer",
  },
  disabledButtonGotoDashboard: {
    backgroundColor: "#f0f0f1",
    color: "#b4b4bb",
    // color:'#ffffff',
    width: "auto",
    height: "40px",
    marginRight: "20px",
    marginBottom: "20px",
    cursor: "pointer",
  },
  bottomMainConatiner: {
    width: "100%",
  },
  newBottomContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "8px",
  },
  bottomChildContainer1: {
    width: "50%",
  },
  bottomChildContainer2: {
    display: "flex",
    width: "50%",
  },
  chatContainer: {
    display: "flex",
    marginLeft: "auto",
  },
  bottomHelpConatiner: {
    display: "flex",
    width: "100%",
  },
  needHelpContainer: {
    color: "#304d63",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "right",
    marginLeft: "auto",
  },
  /* textField: {
    width: '23%',
    height: '56px',
    backgroundColor: '#f1f1f1',
    color: '#19191d',
    borderRadius: '4px',
  },
  textField1: {
    width: '23%',
    height: '56px',
    backgroundColor: '#f1f1f1',
    color: '#19191d',
    borderRadius: '2px',
  },
  selectField: {
    width: '23%',
    height: '56px',
    backgroundColor: '#f1f1f1',
    color: '#19191d',
    borderRadius: '4px'
  },*/
  Text: {
    width: "276px",
    height: "4px",
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
    fontSize: "15px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.6",
    letterSpacing: "0.44px",
    color: "#19191d",
  },
  Text2: {
    width: "95px",
    height: "24px",
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.44px",
    color: "#19191d",
  },
  Bottom: {
    width: "292px",
    height: "1px",
    backgroundColor: "#0f0f0f",
  },
  questionSubtext: {
    // backgroundColor: '#f8f8f8',
    // width: '65%',
    borderRadius: "4px",
    fontSize: "14px",
    maxHeight: "170px",
    minHeight: "20px",
    overflowX: "auto",
    overflowY: "auto",
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
  },

  questionSubtextColor: {
    backgroundColor: "#f8f8f8",
    padding: "14px 15px",
    lineHeight: "16px",
  },

  questionSubtextColor1: {
    backgroundColor: "#f8f8f8",
    padding: "14px 15px",
    marginBottom: "-20px",
  },

  questionSubtextYes: {
    backgroundColor: "#f8f8f8",
    width: "158%",
    borderRadius: "4px",
    fontSize: "14px",
    minHeight: "160px",
    overflowX: "hidden",
    overflowY: "auto",
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
  },
  w70: {
    width: "70%",
    display: "inline-block",
  },

  w30: {
    width: "30%",
    display: "inline-block",
  },
  subTitle: {
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "bold",
    marginBottom: "18px",
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
  },

  instTxt: {
    fontSize: "14px",
    lineHeight: "16px",
    /*fontWeight: 'normal',
    fontFamily: "Roboto",*/
    width: "300px",
    textAlign: "justify",
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
  },
};
export default style;
