import moment from "moment";
import React, { Component } from "react";
import { withRouter } from "react-router";

class Footer extends Component {
  render() {
    return (
      <div>
        <p style={{ textAlign: "center" }}>
          Copyright © {moment().format("YYYY")} netWell. All rights reserved. | Powered by
          CarynHealth
        </p>
      </div>
    );
  }
}
export default withRouter(Footer);
